.App {
  margin: 0px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* .btn {
  background: #fff;
  border: solid 1px #eee;
  padding: 5px 10px;
  margin: 5px;
  cursor: pointer;
  border-radius: 3px;
}
.btn:hover {
  background: #f3f3f3;
  border: solid 1px #e3e3e3;
} */

header {
  background: #eee;
  border-bottom: solid 1px #ddd;
  padding: 20px;
  margin: 0 0 20px;
  text-align: center;
}

nav ul,
nav li {
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline;
}

nav a {
  display: inline-block;
  padding: 5px 10px;
  text-decoration: none;
  font-weight: bold;
}

main {
  padding:50px;
}